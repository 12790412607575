import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const PropertyManagers = () => {
  return (
    <Layout>
      <Seo
        title="Administradores de Fincas - BIKESAFE"
        description="Espacio, valor y seguridad son esenciales para los administradores de fincas. Bike Safe ofrece soluciones de aparcabicicletas adaptadas para todo tipo de instalaciones, optimizando el espacio disponible."
      />
      <Container>
        <Row>
          <Col sm="12">
            <h1 className="mt-5 mb-5 text-center">Administradores de Fincas</h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="text-center">
            <p className="flow-text">
              Espacio, valor y seguridad son aspectos clave para los
              administradores de fincas. En Bike Safe, ofrecemos soluciones de
              aparcabicicletas que se adaptan a todos los tipos de
              instalaciones, ya sea que desee dedicar una sala completa o un
              espacio más reducido para el estacionamiento de bicicletas.
              Contamos con la solución perfecta para maximizar el valor de su
              propiedad.
            </p>
            <p className="flow-text">
              Nuestro servicio de diseño CAD le ayuda a sacar el máximo provecho
              de su espacio, creando un área de estacionamiento para bicicletas
              eficiente y segura.
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="text-center">
            <StaticImage
              placeholder="blurred"
              src="../images/bristol-shelter.jpg"
              className="shadow mt-5 mb-5 fluid rounded"
            />
            <p>
              <em className="flow-text">
                "Muchas gracias por el excelente trabajo realizado con los
                aparcabicicletas. Tienen un aspecto magnífico y ya se están
                utilizando."
                <strong>
                  {" "}
                  John Hilton, SMBC Group (Administradores de fincas), Londres
                </strong>
                .
              </em>
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default PropertyManagers;
